import { WindowLocation } from "@reach/router";
import { graphql } from "gatsby";
import React from "react";
import NewsNavLayout from "../layouts/NewsNavLayout/NewsNavLayout";
import PageLayout from "../layouts/PageLayout/PageLayout";
import { CommonContext, SanityCampaign } from "../model/common";
import { GlobalMenus } from "../model/menu";
import { SanityNewsArticle, SanityNewsArticleMeta } from "../model/news";
import { SiteArea } from "../utils/analytics";

export const pageQuery = graphql`
  query NewsArticlePage($_id: String!, $navLanguage: String) {
    menus: sanityGlobalConfig(language: { eq: $navLanguage }) {
      ...MenuData
    }
    page: sanityNewsArticle(_id: { eq: $_id }) {
      _id
      _type
      title
      metaDescription
      socialTitle
      socialDescription
      socialImage {
        ...SanityImage
      }
      metaDescription
      _rawContent(resolveReferences: { maxDepth: 7 })
      date
    }
    allMeta: allSanityNewsArticle(
      sort: { fields: [date, _updatedAt], order: [DESC, DESC] }
    ) {
      nodes {
        _id
        _type
        title
        date
        slug {
          current
        }
      }
    }
    recent: allSanityNewsArticle(
      sort: { fields: [date, _updatedAt], order: [DESC, DESC] }
      limit: 4
    ) {
      nodes {
        _id
        _type
        title
        date
        slug {
          current
        }
        image {
          ...SanityImage
        }
      }
    }
    campaigns: allSanityCampaign(filter: { activeSiteAreas: { in: "news" } }) {
      nodes {
        ...Campaign
      }
    }
  }
`;

const NewsArticlePage = ({
  pageContext,
  data: { menus, page, campaigns, allMeta, recent },
  location
}: NewsArticlePageProps) => {
  const { title } = page;
  return (
    <PageLayout
      siteArea={SiteArea.NEWS}
      metadata={{
        title,
        page,
        alternates: pageContext.alternates,
        type: "article"
      }}
      menus={menus}
      strings={pageContext.strings}
      location={location}
    >
      <NewsNavLayout
        campaigns={campaigns.nodes}
        article={page}
        allMeta={allMeta.nodes}
        recent={recent.nodes}
      />
    </PageLayout>
  );
};

export default NewsArticlePage;

interface NewsArticlePageProps {
  data: {
    menus: GlobalMenus;
    page: SanityNewsArticle;
    allMeta: { nodes: SanityNewsArticleMeta[] };
    recent: { nodes: SanityNewsArticle[] };
    campaigns: { nodes: SanityCampaign[] };
  };
  location: WindowLocation;
  pageContext: CommonContext;
}
